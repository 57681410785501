<div class="full-window">
  <div>
    <div class="chevron-icon">
      <img src="/assets/chevron-icon.png" alt="Chevron" />
    </div>
    <div class="header">
      <h3 class="project_title">price forecasting hub</h3>
      <div class="profile-section">
        <i class="pi pi-user profile-icon"></i>
        <span class="account-name">User name</span>
      </div>
    </div>
    <nav class="navbar-home-page">
      <ul>
        <li><a routerLink="/settings"><i class="pi pi-cog"></i></a></li>
        <li><a routerLink="/about"><i class="pi pi-info-circle"></i></a></li>
      </ul>
    </nav>
    <div class="selection-bar">
      <!-- Select SAT Dropdown -->
      <label>Select SAT:</label>
      <p-dropdown [options]="sats" [(ngModel)]="selectedSAT"></p-dropdown>

      <!-- Select Region Dropdown -->
      <label>Select Region:</label>
      <p-dropdown [options]="regions" [(ngModel)]="selectedRegion"></p-dropdown>

      <!-- Select Commodity Dropdown -->
      <label>Select commodity:</label>
      <p-dropdown [options]="commodities" [(ngModel)]="selectedCommodity"></p-dropdown>

      <!-- Select Model Dropdown -->
      <label>Select model:</label>
      <p-dropdown [options]="models" [(ngModel)]="selectedModel"></p-dropdown>
    </div>

    <!-- Display Selected Model Name -->

    <hr style=" margin-bottom: -10px;">
    <div class="hub-area">
      <h4 class="model-name">{{ getSelectedModelLabel() }}</h4>
      <div class="model-input">
        <div class="tabs">
          <ng-container *ngFor="let item of items">
            <a [routerLink]="item['route']" class="tab" [ngClass]="{'active': isActive(item['route'])}">
              <span [class]="item.icon"></span>
              {{ item.label }}
            </a>
          </ng-container>
        </div>
        <hr>
        <h5 class=" model-input-heading">Model input</h5>
        <div class="tab-content">
          <router-outlet style="padding-left: 20px;"></router-outlet>
        </div>
      </div>
    </div>
  </div>
</div>