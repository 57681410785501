import { Component, ElementRef, Renderer2 } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import * as XLSX from 'xlsx';
import { DialogModule } from 'primeng/dialog';

interface Row {
  inputsAssumptions: string;
  baseCase: string | number;
  [key: string]: any; // Allows dynamic properties for custom columns
}

interface CustomColumn {
  header: string;
  values: { [key: string]: any }; // Stores values keyed by 'inputsAssumptions'
}

@Component({
  selector: 'app-ModelPrediction',
  standalone: true,
  imports: [CommonModule, FormsModule, FontAwesomeModule, DialogModule],
  templateUrl: './Model-Prediction-Tab.component.html',
  styleUrls: ['./Model-Prediction-Tab.component.scss']
})
export class ModelPrediction {
  columns = [
    { header: 'Inputs and Assumptions', field: 'inputsAssumptions', editable: false },
    { header: 'Base Case', field: 'baseCase', editable: false }
  ];

  rows: Row[] = [
    { inputsAssumptions: 'Run Date', baseCase: '11/11/2024' },
    { inputsAssumptions: 'Settlement Price', baseCase: '2.095' },
    { inputsAssumptions: 'US Supply', baseCase: '1010' },
    { inputsAssumptions: 'US Demand', baseCase: '105.3' },
    { inputsAssumptions: 'US Storage L1', baseCase: '204.4' },
    { inputsAssumptions: 'US Production', baseCase: '100.3' },
    { inputsAssumptions: 'US ResComm', baseCase: '31.3' },
    { inputsAssumptions: 'US LNG Feed SD', baseCase: '0.61' }
  ];

  customColumns: CustomColumn[] = [];
  showPopup = false;
  newColumnName = '';
  baseScenario: CustomColumn | 'Base Case' = 'Base Case';

  constructor(private renderer: Renderer2, private el: ElementRef, library: FaIconLibrary) {
    library.addIcons(faTrash);
  }
  openPopup() {
    this.showPopup = true;
    this.baseScenario = 'Base Case'; // Reset to default base case each time popup opens
  }
  closePopup() {
    this.showPopup = false;
    this.newColumnName = '';
    this.baseScenario = 'Base Case';
  }
  addColumn() {
    if (this.newColumnName.trim()) {
      const newColumn: CustomColumn = {
        header: this.newColumnName,
        values: {}
      };

      // Initialize each row's value based on the selected base case
      this.rows.forEach(row => {
        if (this.baseScenario === 'Base Case') {
          newColumn.values[row.inputsAssumptions] = row.baseCase;
        } else if (this.baseScenario) {
          // Copy values from the selected custom column
          const selectedColumn = this.baseScenario as CustomColumn;
          newColumn.values[row.inputsAssumptions] = selectedColumn.values[row.inputsAssumptions];
        }
      });

      this.customColumns.push(newColumn);
      this.closePopup();
    } else {
      alert('Please enter a scenario name.');
    }
  }
  removeColumn(column: CustomColumn) {
    const index = this.customColumns.indexOf(column);
    if (index > -1) {
      this.customColumns.splice(index, 1);
    }
  }

  isAddButtonDisabled() {
    return !this.newColumnName.trim();
  }

  exportTable(): void {
    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(this.rows);
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    XLSX.writeFile(wb, 'table_data.xlsx');
  }
}
