/* src/app/app.component.scss */
.full-window {
  border: 2px solid rgb(224, 223, 223);
}
.chevron-icon {
  text-align: center;
  z-index: 1000;
}
.chevron-icon img {
  width: 47px;
  height: 52px;
}
.project_title {
  text-align: center;
  margin-top: 1px;
  margin-bottom: 2px;
  color: rgb(17, 159, 210);
  font-weight: bold;
  font-size: 15px;
}
.page-title {
  padding-left: 105px;
  margin-top: 16px;
  margin-bottom: 20px;
  color: dodgerblue;
}
.active-link {
  color: rgb(255, 255, 255);
  background-color: rgb(33, 147, 224);
}
.card {
  margin: 5px;
  padding: 10px;
  background-color: #f8f9fa;
  border-radius: 5px;
}
.p-menuitem-link {
  display: flex;
  align-items: center;
  padding: 10px 15px;
  text-decoration: none;
  color: #495057;
  transition: background-color 0.3s ease;
}
.p-menuitem-link:hover {
  background-color: #e9ecef;
  color: #007bff;
}
.p-menuitem-link .ml-2 {
  margin-left: 8px;
}
.p-menuitem-link .pi {
  font-size: 18px;
}
.p-menuitem-link.active {
  background-color: rgb(8, 0, 255);
  color: white;
}
.tabs {
  display: flex;
  flex-direction: row;
  margin-bottom: -3px;
  padding-left: 15px;
  font-size: 12px;
}
.tabs .tab {
  text-decoration: none;
  color: #999;
  padding: 0px 10px;
  position: relative;
}
.tabs .tab.active {
  font-weight: bold;
  color: black;
}
.tabs .tab.active::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 2px;
  background-color: orange;
  left: 0;
  bottom: -5px;
}
.tabs .tab:hover {
  color: #666;
}
.header {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}
.header .project_title {
  margin: 0;
  font-size: 24px;
  flex-grow: 1;
  text-align: center;
}
.header .profile-section {
  position: absolute;
  right: 20px;
  display: flex;
  align-items: center;
}
.header .profile-icon {
  font-size: 20px;
  margin-right: 10px;
}
.header .account-name {
  font-size: 16px;
  color: gray;
}
.tab-content {
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 0px 20px 20px 20px;
  margin-top: 10px;
  background-color: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}
.navbar-home-page {
  background-color: #002D71;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 5px;
  margin-bottom: 5px;
}
.navbar-home-page ul {
  list-style: none;
  display: flex;
  margin: 0;
  padding: 0;
}
.navbar-home-page ul li {
  margin-right: 20px;
}
.navbar-home-page ul li a {
  color: white;
  text-decoration: none;
  padding: 0.2rem;
  display: flex;
  align-items: center;
  height: 100%;
}
.navbar-home-page ul li a:hover {
  background-color: #1093cb;
}
.selection-bar {
  display: flex;
  justify-content: center;
  align-items: center;
  width: fit-content;
  margin: 0 auto;
  border: 2px solid rgb(224, 223, 223);
  padding: 5px;
  gap: 5px;
  border-radius: 5px;
}
.selection-bar label {
  font-size: 12px;
}
.selection-bar ::ng-deep .p-dropdown {
  width: 130px;
  height: 25px;
}
.selection-bar ::ng-deep .p-dropdown .p-dropdown-item {
  font-size: 12px;
}
.selection-bar ::ng-deep .p-dropdown .p-dropdown-label {
  display: flex;
  align-items: center;
  height: 100%;
  font-size: 12px;
}
.hub-area {
  margin-top: 10px;
  background-color: #f5f5f5;
  padding: 10px 15px;
  border-radius: 8px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
}
.model-input {
  border: 1px solid #ccc;
  padding: 15px;
  margin: 10px 0;
  background-color: #ffffff;
  border-radius: 5px;
}
.model-name {
  margin: 0 0 10px 10px;
  color: #003366;
  font-weight: bold;
}
.model-input-heading {
  padding-left: 30px;
  margin: 10px 0;
  font-size: 12px;
  color: #333333;
}
/*# sourceMappingURL=app.component-P5WA3FYP.css.map */
