import { Routes} from '@angular/router';
import { ModelPrediction } from './components/Model-Prediction-Tab/Model-Prediction-Tab.component';
import { TradingStrategy } from './components/Trading-Strategy-Tab/Trading-Strategy-Tab.component';
import { FeedbackComponent } from './components/feedback/feedback.component';


export const routes: Routes = [
    // { path: '', component: LandingPageComponent }, // Landing page route
    { path: 'Model-Prediction', component:ModelPrediction },
    { path: 'Trading-Strategy', component: TradingStrategy },
    { path: 'feedback', component: FeedbackComponent},
    { path: '**', component: ModelPrediction }
];
