import { Component } from '@angular/core';

@Component({
  selector: 'app-second',
  standalone: true,
  imports: [],
  templateUrl: './Trading-Strategy-Tab.component.html',
  styleUrls: ['./Trading-Strategy-Tab.component.scss']
})
export class TradingStrategy {

}
