/* src/app/components/Model-Prediction-Tab/Model-Prediction-Tab.component.scss */
.model-inputs-container {
  max-width: 600px;
  position: relative;
  left: 0;
  display: flex;
  justify-items: flex-start;
}
.model-inputs-container .table-wrapper {
  max-height: 250px;
  overflow-y: auto;
  width: 100%;
}
.model-inputs-container .table-wrapper .model-inputs-table {
  width: 100%;
  border-collapse: collapse;
  table-layout: fixed;
  border: 1px solid #ccc;
}
.model-inputs-container .table-wrapper .model-inputs-table thead,
.model-inputs-container .table-wrapper .model-inputs-table tbody tr {
  display: table;
  width: 100%;
  table-layout: fixed;
}
.model-inputs-container .table-wrapper .model-inputs-table thead {
  position: sticky;
  top: 0;
  background-color: #f5f5f5;
  z-index: 1;
}
.model-inputs-container .table-wrapper .model-inputs-table .inputs-header,
.model-inputs-container .table-wrapper .model-inputs-table .base-case-header,
.model-inputs-container .table-wrapper .model-inputs-table .custom-case-header {
  background-color: #f5f5f5;
  font-weight: bold;
  text-align: center;
  padding: 8px 10px;
  border: 1px solid #ccc;
  font-size: 12px;
}
.model-inputs-container .table-wrapper .model-inputs-table .input-label {
  padding: 8px 10px;
  font-weight: bold;
  text-align: left;
  border-right: 1px solid #ccc;
  font-size: 12px;
}
.model-inputs-container .table-wrapper .model-inputs-table .base-case,
.model-inputs-container .table-wrapper .model-inputs-table .custom-case {
  padding: 8px 10px;
  text-align: center;
  border-right: 1px solid #ccc;
  font-size: 12px;
}
.model-inputs-container .table-wrapper .model-inputs-table .header-container {
  display: flex;
  align-items: center;
}
.model-inputs-container .table-wrapper .model-inputs-table .header-container .header-input {
  width: 100%;
  border: none;
  text-align: center;
  font-weight: bold;
  background-color: #f5f5f5;
}
.model-inputs-container .table-wrapper .model-inputs-table .header-container .delete-button {
  background: transparent;
  border: none;
  color: #E5601F;
  cursor: pointer;
  font-size: 10px;
}
.model-inputs-container .add-button {
  background-color: #E5601F;
  color: #fff;
  border: none;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  cursor: pointer;
  font-size: 20px;
  margin-left: 10px;
}
.popup-add-cancel {
  margin-top: 10px;
  display: flex;
  justify-content: flex-end;
  gap: 20px;
}
.cancel-button {
  border: 1px solid #0066B2;
  background-color: white;
  color: #0066B2;
  padding: 5px 10px;
}
.input-row {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-top: 10px;
  border: 1px solid lightgrey;
  padding: 10px;
}
.active-button {
  background-color: #0066b2;
  color: white;
  border: none;
  padding: 5px 10px;
}
.inactive-button {
  background-color: grey;
  color: white;
  border: none;
  padding: 5px 10px;
}
.value-input,
.header-input {
  width: 100%;
  text-align: center;
  border: none;
}
.value-input:focus,
.header-input:focus {
  outline: none;
  background-color: #e0e0e0;
}
/*# sourceMappingURL=Model-Prediction-Tab.component-CZQYLWJV.css.map */
