import { Component, OnInit } from '@angular/core';
import { RouterOutlet, RouterLinkActive, RouterLink,Router } from '@angular/router';
import { CommonModule } from '@angular/common';
import { TabMenuModule } from 'primeng/tabmenu';
import { MenuItem, MessageService } from 'primeng/api';
import { ToastModule } from 'primeng/toast';
import { DropdownModule } from 'primeng/dropdown';
// import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { FormsModule } from '@angular/forms';




@Component({
  selector: 'app-root',
  standalone: true,
  imports: [CommonModule, RouterOutlet, RouterLink, RouterLinkActive, TabMenuModule, ToastModule, DropdownModule, FormsModule],
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  providers: [MessageService]
 
})
export class AppComponent  implements OnInit {
  title(title: any) {
    throw new Error('Method not implemented.');
  }
  items: MenuItem[] | undefined;
  constructor(private router: Router) {}
  isActive(route: string): boolean {
    return this.router.url === route || (this.router.url === '/' && route === '/Model-Prediction')
  }

  ngOnInit() {
    this.items = [
      {label: 'Model Predictions', 
      route: '/Model-Prediction'
    },
      {label: 'Trading Strategy', 
      route: '/Trading-Strategy'
    },
    {label: 'Feedback', 
      route: '/feedback'
    }
    ]
  }

  sats = [
    { label: 'SAT 1', value: 'sat1' },
    { label: 'SAT 2', value: 'sat2' },
    { label: 'SAT 3', value: 'sat3' },
    { label: 'SAT 4', value: 'sat4' },
    { label: 'SAT 5', value: 'sat5' },
  ];

  regions = [
    { label: 'Region 1', value: 'region1' },
    { label: 'Region 2', value: 'region2' },
    { label: 'Region 3', value: 'region3' },
    { label: 'Region 4', value: 'region4' },
    { label: 'Region 5', value: 'region5' },
  ];

  commodities = [
    { label: 'Commodity 1', value: 'commodity1' },
    { label: 'Commodity 2', value: 'commodity2' },
    { label: 'Commodity 3', value: 'commodity3' },
    { label: 'Commodity 4', value: 'commodity4' },
    { label: 'Commodity 5', value: 'commodity5' },
  ];

  models = [
    { label: 'Henry Hub', value: 'HenryHub' },
    { label: 'Model 2', value: 'model2' },
    { label: 'Model 3', value: 'model3' },
    { label: 'Model 4', value: 'model4' },
    { label: 'Model 5', value: 'model5' },
  ];

  // Model to bind selected values
  selectedSAT: any = null;
  selectedRegion: any = null;
  selectedCommodity: any = null;
  selectedModel: any = 'HenryHub';

  getSelectedModelLabel(): string {
    const selected = this.models.find(model => model.value === this.selectedModel);
    return selected ? selected.label : '';
  }

}
