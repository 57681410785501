<p-dialog header="Create a custom scenario" [(visible)]="showPopup" [modal]="true" [closable]="false">
  <div class="input-row">
    <label for="scenarioName">Set Scenario Name:</label>
    <input id="scenarioName" [(ngModel)]="newColumnName" />
    <label for="baseScenario">Select Base Scenario:</label>
    <select id="baseScenario" [(ngModel)]="baseScenario">
      <option value="Base Case">Base Case</option>
      <option *ngFor="let scenario of customColumns" [ngValue]="scenario">{{ scenario.header }}</option>
    </select>
  </div>
  <div class="popup-add-cancel">
    <button class="cancel-button" (click)="closePopup()">Cancel</button>
    <button [disabled]="isAddButtonDisabled()"
      [ngClass]="{'active-button': !isAddButtonDisabled(), 'inactive-button': isAddButtonDisabled()}"
      (click)="addColumn()">Create</button>
  </div>
</p-dialog>

<div class="model-inputs-container">
  <!-- Added .table-wrapper to control scrolling -->
  <div class="table-wrapper">
    <table class="model-inputs-table">
      <thead>
        <tr>
          <th class="inputs-header">Inputs and Assumptions</th>
          <th class="base-case-header">Base Case</th>
          <th *ngFor="let column of customColumns" class="custom-case-header">
            <div class="header-container">
              <input [(ngModel)]="column.header" class="header-input" />
              <button (click)="removeColumn(column)" class="delete-button">
                <fa-icon [icon]="['fas', 'trash']"></fa-icon>
              </button>
            </div>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let row of rows">
          <td class="input-label">{{ row.inputsAssumptions }}</td>
          <td class="base-case">{{ row.baseCase }}</td>
          <td *ngFor="let column of customColumns" class="custom-case">
            <input [(ngModel)]="column.values[row.inputsAssumptions]" class="value-input" />
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <button (click)="openPopup()" class="add-button">+</button>
</div>